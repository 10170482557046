var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table table-bordered" },
    [
      _vm._m(0),
      _vm._l(_vm.releases, function(release, index) {
        return _c("tr", { key: index }, [
          _c("td", { staticClass: "text-center" }, [
            _c("img", {
              attrs: {
                src:
                  "https://assets.the-source.eu/frontcover/" +
                  release.ProductDetails.ReportingCode +
                  "/200?refresh=" +
                  new Date().getTime(),
                width: "100"
              }
            })
          ]),
          _c(
            "td",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "release-detail",
                      params: { id: release.AlbumID }
                    }
                  }
                },
                [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(_vm._s(release.ProductDetails.Title))
                  ]),
                  _c("p", { staticClass: "attribution" }, [
                    _c("em", [
                      _vm._v(_vm._s(release.ProductDetails.Attribution))
                    ])
                  ])
                ]
              ),
              _c("p", { staticClass: "release-date" }, [
                _vm._v(_vm._s(_vm._f("dateFormat")(release.ReleaseDate)))
              ]),
              _vm.showDeliveryStatus
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.deliveryStatusFormatter(release))
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("Artwork")]),
      _c("th", [_vm._v("Title / Attribution / Release Date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
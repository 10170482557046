<template>
  <table class="table table-bordered">
    <thead>
      <th>Artwork</th>
      <th>Title / Attribution / Release Date</th>
    </thead>
    <tr v-for="(release, index) in releases" :key="index">
      <td class="text-center">
        <img v-bind:src="'https://assets.the-source.eu/frontcover/'+release.ProductDetails.ReportingCode+'/200?refresh=' + new Date().getTime()" width="100" />
      </td>
      <td>
        <router-link :to="{ name: 'release-detail', params: { id: release.AlbumID } }">
          <p class="title">{{ release.ProductDetails.Title }}</p>
          <p class="attribution"><em>{{ release.ProductDetails.Attribution }}</em></p>
        </router-link>
        <p class="release-date">{{ release.ReleaseDate|dateFormat }}</p>
        <p v-if="showDeliveryStatus" v-html="deliveryStatusFormatter(release)"></p>
      </td>
    </tr>
  </table>
</template>

<script>
  export default {
    name: 'ReleaseWidget',
    data () {
      return {
        releases: null
      }
    },
    props: {
      filter: Object,
      sortBy: String,
      sortDesc: Boolean,
      showDeliveryStatus: Boolean,
    },
    methods: {
      deliveryStatusFormatter(data) {
        const ds = data.ProductDetails.DeliveryStatus
        let desc = this.$root.$data.fieldDefs.Album.AlbumDeliveryStatus.Options.find(s => {return s.JsonValue == ds.StatusID})
        if (desc) {
          desc = desc.Value
        } else {
          desc = ds.Description
        }

        return desc 
      }
    },
    mounted () {
      let ctx = {
        perPage: 10,
        currentPage: 1,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filter: this.filter
      }
      this.$http
        .get('albums', { params: ctx })
        .then(response => {
          this.releases = response.data
        }).catch(function (e) {
            console.log(e)
        })
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0.5rem;
  }
  p.release-date {
    margin-top: 1rem;
  }
</style>